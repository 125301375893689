<template>
  <div>
    <b-card-actions
        ref="refreshCard"
        action-refresh
        disable-loading-on-refresh
        @refresh="getItems"
        :loaded.sync="loaded"
    >
      <table-header :filter="filter" :per-page.sync="perPage" />

      <b-overlay :show="!loaded" rounded="sm">
        <b-table
            ref="refaccountCampaignListTable"
            class="position-relative"
            responsive
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="tableColumns"
            :sort-by.sync="sortBy"
            :filter="filter.input"
            @filtered="onFiltered"
            show-empty
            :empty-text="$t('No matching records found')"
        >
          <template #cell(name)="data">
            <span v-if="data.item.users.length > 0"> {{ data.item.users[0].name }}</span>
          </template>
          <template #cell(actions)="data">
            <b-button
                :to="{
                name: 'apps-client-users-booking-details',

               params: { clientId: $route.params.clientid, userid: $route.params.userid, bookingid:data.item.id  }}"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="submit"
                size="sm"
            >
              {{ $t('Details') }}
            </b-button>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                type="submit"
                size="sm"
                @click="deleteItem(data.item.id)"
            >
              {{ $t('Delete') }}
            </b-button>
          </template>
        </b-table>
      </b-overlay>

      <table-footer
          :current-page.sync="currentPage"
          :per-page="perPage"
          :length="totalRows" />
    </b-card-actions>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import TableHeader from '@/views/components/TableHeader'
import TableFooter from '@/views/components/TableFooter'
import { BCardActions } from '@core/components/b-card-actions'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    TableHeader,
    TableFooter,
    BCardActions,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loaded: false,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: {
        input: null
      },
      tableColumns: [
        {
          key: 'start_date',
          sortable: true,
          label: this.$i18n.t('Event Date'),
          formatter: (value) => {
            return moment(String(value))
                .format('DD.MM.YYYY')
          },
        },
        {
          key: 'name',
          sortable: true,
          label: this.$i18n.t('Name'),
        },

        {
          key: 'event.name',
          sortable: true,
          label: 'Event name',
        },

        {
          key: 'credits',
          sortable: true,
          label: '# Credits',
        },
        {
          key: 'event.host',
          sortable: true,
          label: this.$i18n.t('Host'),
        },

        {
          key: 'actions',
          label: this.$i18n.t('Actions'),
          class: 'table-action-buttons'
        },
      ],
      items: [],
    }
  },
  beforeMount() {
    this.addBreadcrumbDataFromRouteParams(this.$route.params)
  },
  mounted() {
    this.getItems()
  },
  methods: {
    getItems() {
      this.helperGetItems(`/clients/${this.$route.params.clientid}/users/${this.$route.params.userid}/bookings`)
    },
    deleteItem(id) {
      this.helperDeleteItem(
          `clients/${this.$route.params.clientid}/users/${this.$route.params.userid}/bookings/${id}`,
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
